import { ICustomizationSchema } from '@bitpagg/ui-react-core/dist/contexts/resource/customization-schema';

import Http from '../http';

export interface ResourceOptions {
  onCustomizationUpdate(): void;
}

export const customization = (
  options: ResourceOptions
): ICustomizationSchema => ({
  async updateAppearance(payload, reference) {
    const { data, status } = await Http.patch(
      `bff-simulefacil/v1/configuracoes-layout/pagina-destino/aparencia/${reference}`,
      {
        tema: {
          titulo: payload.title,
          cores: {
            primario: payload.colors.primary,
            secundario: payload.colors.secondary,
          },
          logo: {
            url: payload.logo.url,
          },
        },
      }
    );

    if (status === 202) {
      options.onCustomizationUpdate();
      return {
        status: 'OK',
        payload: { id: data.id, ...payload },
      };
    }
    return {
      status: 'FAIL',
      payload,
      message: data?.detail ?? 'Erro ao salvar a customizaçãos',
    };
  },
  async updateAboutStaticPage(payload, reference) {
    const { data, status } = await Http.patch(
      `bff-simulefacil/v1/configuracoes-layout/pagina-destino/sobre/${reference}`,
      {
        sobre: {
          menu: {
            label: payload.menu?.label,
            visivel: payload.menu?.visible,
          },
          conteudo: payload.content,
        },
      }
    );

    if (status === 202) {
      options.onCustomizationUpdate();
      return {
        status: 'OK',
        payload: { id: data.id, ...payload },
      };
    }
    return {
      status: 'FAIL',
      payload,
      message: data?.detail ?? 'Erro ao salvar a customizaçãos',
    };
  },
  async updateProductStaticPage(payload, reference) {
    const { data, status } = await Http.patch(
      `bff-simulefacil/v1/configuracoes-layout/pagina-destino/produto/${reference}`,
      {
        produto: {
          menu: {
            label: payload.menu.label,
            visivel: payload.menu.visible,
          },
          secoes: payload.sections.map((section) => ({
            conteudo: section.content,
          })),
        },
      }
    );

    if (status === 202) {
      options.onCustomizationUpdate();
      return {
        status: 'OK',
        payload: { id: data.id, ...payload },
      };
    }
    return {
      status: 'FAIL',
      payload,
      message: data?.detail ?? 'Erro ao salvar a customizaçãos',
    };
  },
  async updateContactStaticPage(payload, reference) {
    const { data, status } = await Http.patch(
      `bff-simulefacil/v1/configuracoes-layout/pagina-destino/contato/${reference}`,
      {
        contato: {
          menu: {
            label: payload.menu?.label,
            visivel: payload.menu?.visible,
          },
          informacao: {
            email: {
              icone: {
                url: payload.content.email.icon.url,
              },
              label: payload.content.email.label,
              valor: payload.content.email.value,
            },
            telefone: {
              icone: {
                url: payload.content.phone.icon.url,
              },
              label: payload.content.phone.label,
              valor: payload.content.phone.value,
            },
          },
        },
      }
    );

    if (status === 202) {
      options.onCustomizationUpdate();
      return {
        status: 'OK',
        payload: { id: data.id, ...payload },
      };
    }
    return {
      status: 'FAIL',
      payload,
      message: data?.detail ?? 'Erro ao salvar a customizaçãos',
    };
  },
  async upload(file: File) {
    const formData = new FormData();
    formData.append('', file);

    const { data, status } = await Http.post(
      `bff-simulefacil/v1/configuracoes-layout/pagina-destino/upload`,
      formData
    );

    if (status === 200) return data;
    return null;
  },
});
