import { IUserSchema } from '@bitpagg/ui-react-core/dist/contexts/resource/user-schema';

import Http from '../http';

export const user = (): IUserSchema => ({
  async getPasswordPolicy() {
    const { data, status } = await Http.get(
      `bff-simulefacil/v1/politica-de-senhas`
    );

    if (status === 200) {
      return {
        minLength: data.minimoDeCaracteres,
        maxLength: data.maximoDeCaracteres,
        minUppercaseCharacters: data.minimoDeCaracteresMaiusculos,
        minLowercaseCharacters: data.minimoDeCaracteresMinusculos,
        minSpecialCharacters: data.minimoDeCaracteresEspeciais,
        minNumericCharacters: data.minimoDeCaracteresEspeciais,
        expiration: data.tempoDeExpiracao,
        passwordsForRepetition: data.senhasParaRepeticao,
        blockUseEmail: data.bloqueiaUsoDoEmailNaSenha,
        blockUseUsername: data.bloqueiaUsoDoNomeDeUsuariolNaSenha,
      } as any;
    }
    return null;
  },
  async getProfiles() {
    const { data, status } = await Http.get(`bff-simulefacil/v1/perfis`);

    if (status === 200) {
      return data.map((profile: any) => ({
        title: profile.nome,
        value: profile.nome,
        keywords: [profile.nome],
        data: profile,
      }));
    }
    return [];
  },
  async getUsers() {
    const { data, status } = await Http.get('bff-simulefacil/v1/usuarios');

    if (status === 200) {
      return data.map((u: any) => ({
        id: u.id,
        name: u.primeiroNome,
        lastname: u.sobreNome,
        uname: u.nomeDeUsuario,
        email: u.email,
        active: u.ativo,
      }));
    }

    return [];
  },
  async createUser(payload: any) {
    try {
      const { data, status } = await Http.post(`bff-simulefacil/v1/usuarios`, {
        primeiroNome: payload.name,
        sobreNome: payload.lastname,
        nomeDeUsuario: payload.username,
        email: payload.email,
        ativo: true,
        credenciais: [
          {
            tipo: 'password',
            valor: payload.password,
            temporario: payload.passwordTemporary,
          },
        ],
        atributos: {
          Cpf: [payload.document],
        },
        perfis: payload.profiles,
        rolesReino: [],
      });

      if (status === 201) {
        return {
          status: 'OK',
          payload: { id: data.id, ...payload },
        };
      }
      return {
        status: 'FAIL',
        payload,
        message: data?.detail ?? 'Erro ao salvar o usuário',
      };
    } catch {
      return {
        status: 'FAIL',
        payload,
        message: 'Erro ao salvar o usuário',
      };
    }
  },
});
