import { Context } from '@bitpagg/ui-react-core';

import { customization, ResourceOptions } from './customization';
import { product } from './product';
import { setting } from './setting';
import { user } from './user';

export const resources = (
  options: ResourceOptions
): Context.Resource.IServiceSchema => ({
  user: user(),
  customization: customization(options),
  product: product(),
  setting: setting(),
});

export default resources;
