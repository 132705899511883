import { Context } from '@bitpagg/ui-react-core';
import { ISettingSchema } from '@bitpagg/ui-react-core/dist/contexts/resource/setting-schema';
import { IHierarchy } from '@bitpagg/ui-react-core/dist/contexts/resource/setting-schema/interfaces/hierarchy';
import { IHierarchyLevel } from '@bitpagg/ui-react-core/dist/contexts/resource/setting-schema/interfaces/level-hierarchy';
import {
  ISaleChannel,
  ISaleChannelOption,
} from '@bitpagg/ui-react-core/dist/contexts/resource/setting-schema/interfaces/sale-channel';

interface ICanalDeVenda {
  id: string | null;
  idParceiro: string;
  nomeCanalVendas: string;
  idNoParceiro: string | null;
}
interface IHierarquia extends ICanalDeVenda {
  nomeHierarquias: string[] | null;
}
interface ICadastrarHierarquia {
  idCanalVendas: string;
  nomeHierarquia: string;
  nivelHierarquico: number;
  idParceiro: string;
}
interface IMensagem {
  tag: string;
  titulo: string;
  mensagem: string;
  tipo: 'Sucesso' | 'Erro';
}
interface IMetadata {
  totalPaginas: number;
  pagina: number;
  limitePorPagina: number;
  totalItems: number;
}
type IRespostaCanalVendasPorParceiro = IResposta<IHierarquia[]>;

interface IRespostaCanalDeVenda {
  data: {
    canaisDeVenda: ICanalDeVenda[];
    metadata: IMetadata;
  };
  mensagem: IMensagem;
}
interface IResposta<T> {
  data: T;
  mensagem: IMensagem;
}
interface IRespostaHierarquia {
  data: {
    canaisDeVenda: IHierarquia[];
    metadata: IMetadata;
  };
  mensagem: IMensagem;
}
interface IResposta<T> {
  data: T;
  mensagem: IMensagem;
}

export const setting = (): ISettingSchema => ({
  // #region User
  async createUser(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/...`,
      payload
    );

    if (status === 201) {
      return {
        status: 'OK',
        payload: data[0],
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },
  // #endregion

  // #region Hierarchy
  async getHierarchys({ page }: { page: number }) {
    const { status, data } =
      await Context.Resource.http.get<IRespostaHierarquia>(
        `bff-simulefacil/v1/canal-vendas/hierarquia`,
        {
          params: {
            pagina: page ?? 1,
            elementosPorPagina: 10,
          },
        }
      );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          items: data.data.canaisDeVenda.map(
            ({
              id,
              idNoParceiro,
              idParceiro,
              nomeCanalVendas,
              nomeHierarquias,
            }) =>
              <IHierarchy>{
                id,
                partnerId: idParceiro,
                idInPartner: idNoParceiro,
                saleChannel: {
                  id: '',
                  name: nomeCanalVendas,
                },
                hierarchyLevels: nomeHierarquias,
              }
          ),
          meta: {
            page: data.data?.metadata?.pagina ?? 1,
            pages: data.data?.metadata?.totalPaginas ?? 1,
            limit: data.data?.metadata?.limitePorPagina ?? 10,
            totalItems: data.data?.metadata?.totalPaginas ?? 1,
          },
        },
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      payload: undefined,
      message: data?.mensagem?.mensagem,
    };
  },

  async createHierarchy(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/hierarquia`,

      payload.levelList.map(
        ({ idInPartner, hierarchyLevel, hierarchyLevelName, saleChannelId }) =>
          <ICadastrarHierarquia>{
            idCanalVendas: saleChannelId,
            nomeHierarquia: hierarchyLevelName,
            nivelHierarquico: Number(hierarchyLevel),
            idParceiro: idInPartner,
          }
      )
    );

    if (status === 201) {
      return {
        status: 'OK',
        payload: data[0],
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },

  async deleteHierarchy(id) {
    const { data, status } = await Context.Resource.http.delete(
      `bff-simulefacil/v1/hierarquia/${id}`
    );

    if (status === 204) {
      return {
        status: 'OK',
        payload: true,
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },

  async getHierarchyLevels() {
    const { status, data } =
      await Context.Resource.http.get<IRespostaHierarquia>(
        `bff-simulefacil/v1/...`
      );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          items: {} as IHierarchyLevel[],
          meta: {
            page: data.data?.metadata?.pagina ?? 1,
            pages: data.data?.metadata?.totalPaginas ?? 1,
            limit: data.data?.metadata?.limitePorPagina ?? 10,
            totalItems: data.data?.metadata?.totalPaginas ?? 1,
          },
        } as any,
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      payload: undefined,
      message: data?.mensagem?.mensagem,
    };
  },
  // #region  SaleChannel
  async getSaleChannels({ page }) {
    const { status, data } =
      await Context.Resource.http.get<IRespostaCanalDeVenda>(
        `bff-simulefacil/v1/canal-vendas`,
        {
          params: {
            pagina: page ?? 1,
            elementosPorPagina: 10,
          },
        }
      );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          items: data.data.canaisDeVenda.map(
            ({ id, idNoParceiro, idParceiro, nomeCanalVendas }) =>
              <ISaleChannel>{
                id,
                partner: {
                  id: idParceiro,
                },
                salePartnerId: idNoParceiro,
                name: nomeCanalVendas,
              }
          ),
          meta: {
            page: data.data?.metadata?.pagina ?? 1,
            pages: data.data?.metadata?.totalPaginas ?? 1,
            limit: data.data?.metadata?.limitePorPagina ?? 10,
            totalItems: data.data?.metadata?.totalPaginas ?? 1,
          },
        },
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      payload: undefined,
      message: data?.mensagem?.mensagem,
    };
  },

  async getSaleChannelOptions() {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/canal-vendas`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ id, descricaoParceiro }: any) =>
            <ISaleChannelOption>{
              id,
              description: descricaoParceiro,
            }
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },

  async getSaleChannelOptionsByPartner(partnerId) {
    const { data, status } =
      await Context.Resource.http.get<IRespostaCanalVendasPorParceiro>(
        `bff-simulefacil/v1/canal-vendas/parceiro/${partnerId}`
      );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.data.map(
          ({ id, nomeCanalVendas }) =>
            <ISaleChannelOption>{
              id,
              description: nomeCanalVendas,
            }
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },

  async getSaleChannelById(id) {
    const { data, status } = await Context.Resource.http.get<
      IResposta<ICanalDeVenda>
    >(`bff-simulefacil/v1/canal-vendas/${id}`);

    if (status === 200) {
      return {
        status: 'OK',
        payload: <ISaleChannel>{
          id: data.data.id,
          name: data.data.nomeCanalVendas,
          partner: {
            id: data.data.idParceiro,
          },
          salePartnerId: data.data.idNoParceiro,
        },
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },

  async createSaleChannel(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/canal-vendas`,
      [
        {
          id: null,
          idParceiro: payload.partner.id,
          nomeCanalVendas: payload.name,
          idNoParceiro: payload.salePartnerId,
        },
      ]
    );

    if (status === 201) {
      return {
        status: 'OK',
        payload: data.data[0],
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },

  async updateSaleChannel(id, payload) {
    const { data, status } = await Context.Resource.http.put(
      `bff-simulefacil/v1/canal-vendas`,
      {
        id,
        idParceiro: payload.partner.id,
        nomeCanalVendas: payload.name,
        idNoParceiro: payload.salePartnerId,
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.data[0],
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },

  async deleteSaleChannel(id) {
    const { data, status } = await Context.Resource.http.delete(
      `bff-simulefacil/v1/canal-vendas/${id}`
    );

    if (status === 204) {
      return {
        status: 'OK',
        payload: true,
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },
  // #endregion

  // #region LinkHierarchy
  async createLinkHierarchy(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/canal-vendas`,
      payload
    );

    if (status === 201) {
      return {
        status: 'OK',
        payload: data.data[0],
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },

  async getLinkHierarchys({ page }: { page: number }) {
    const { status, data } =
      await Context.Resource.http.get<IRespostaHierarquia>(
        `bff-simulefacil/v1/canal-vendas/hierarquia`,
        {
          params: {
            pagina: page ?? 1,
            elementosPorPagina: 10,
          },
        }
      );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          // items: data.data.canaisDeVenda.map(
          //   ({
          //     id,
          //     idNoParceiro,
          //     idParceiro,
          //     nomeCanalVendas,
          //     nomeHierarquias,
          //   }) =>
          //     <ILinkHierarchy>{
          //       id,
          //       partnerId: idParceiro,
          //       idInPartner: idNoParceiro,
          //       saleChannel: {
          //         id: '',
          //         name: nomeCanalVendas,
          //       },
          //       hierarchyLevels: nomeHierarquias,
          //     }
          // ),
          items: [],
          meta: {
            page: data.data?.metadata?.pagina ?? 1,
            pages: data.data?.metadata?.totalPaginas ?? 1,
            limit: data.data?.metadata?.limitePorPagina ?? 10,
            totalItems: data.data?.metadata?.totalPaginas ?? 1,
          },
        },
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      payload: undefined,
      message: data?.mensagem?.mensagem,
    };
  },

  async getLinkHierarchyById(id) {
    const { data, status } = await Context.Resource.http.get<
      IResposta<ICanalDeVenda>
    >(`bff-simulefacil/v1/...${id}`);

    if (status === 200) {
      return {
        status: 'OK',
        // payload: <ISaleChannel>{
        //   id: data.data.id,
        //   name: data.data.nomeCanalVendas,
        //   partner: {
        //     id: data.data.idParceiro,
        //   },
        //   salePartnerId: data.data.idNoParceiro,
        // },
        payload: {} as any,
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },

  async updateLinkHierarchy(id, payload) {
    const { data, status } = await Context.Resource.http.put(
      `bff-simulefacil/v1/canal-vendas/${id}`,
      payload
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.data[0],
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },

  async deleteLinkHierarchy(id) {
    const { data, status } = await Context.Resource.http.delete(
      `bff-simulefacil/v1/canal-vendas/${id}`
    );

    if (status === 204) {
      return {
        status: 'OK',
        payload: true,
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },

  // #endregion

  // #region Groups
  async getGroupsByLevel(id) {
    const { data, status } = await Context.Resource.http.get<
      IResposta<ICanalDeVenda>
    >(`bff-simulefacil/v1/canal-vendas/${id}`);

    if (status === 200) {
      return {
        status: 'OK',
        // payload: <ISaleChannel>{
        //   id: data.data.id,
        //   name: data.data.nomeCanalVendas,
        //   partner: {
        //     id: data.data.idParceiro,
        //   },
        //   salePartnerId: data.data.idNoParceiro,
        // },
        payload: {} as any,
        message: data?.mensagem?.mensagem,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem?.mensagem,
    };
  },

  // #endregion
});
