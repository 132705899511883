import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Box,
  CircularProgress,
  createTheme,
  CssBaseline,
  ThemeProvider,
} from '@mui/material';

import { getWorkspace } from '../helpers/workspace';

export interface WorkspaceContextProps {
  reload(): Promise<void>;
  schema: {
    id: string | null;
    keycloak: {
      url: string;
      realm: string;
      clientId: string;
      sessionIdle: number;
    };
    pages: {
      static: {
        about: {
          menu: {
            label: string;
            visible: boolean;
          };
          content: string;
        };
        contact: {
          menu: {
            label: string;
            visible: boolean;
          };
          content: {
            email: {
              icon: {
                url: string;
              };
              label: string;
              value: string;
            };
            phone: {
              icon: {
                url: string;
              };
              label: string;
              value: string;
            };
          };
        };
        product: {
          menu: {
            label: string;
            visible: boolean;
          };
          header: {
            title: string;
            subtitle: string;
          };
          sections: {
            content: string;
          }[];
        };
      };
    };
    auth: {
      username: {
        type: 'email';
      };
    };
    theme: {
      title: string;
      colors: {
        primary: string;
        secondary: string;
      };
      logo: {
        url: string;
        alt: string;
      };
      icon: {
        url: string;
      };
      font: {
        family: string;
        url: string;
      };
      schema: {
        buttons: {
          social: {
            use: string;
            position: string;
          };
        };
        hero: {
          use: 'columns.header.image' | 'image';
          options:
            | {
                title: {
                  text: string;
                  fontSize: number;
                };
                subtitle: {
                  text: string;
                  fontSize: number;
                };
                background: {
                  url: string;
                  size: number;
                };
              }
            | {
                background: {
                  url: string;
                  size: number;
                };
              };
        };
      };
    };
  };
}

const WorkspaceContext = createContext<WorkspaceContextProps>(
  {} as WorkspaceContextProps
);

export const useWorkspace = () => {
  const context = useContext(WorkspaceContext);
  if (!context) throw new Error('workspace provider required');
  return context;
};

const WorkspaceProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [workspace, setWorkspace] = useState<
    WorkspaceContextProps['schema'] | undefined
  >();

  const fetchWorkspace = useCallback(async () => {
    const data = await getWorkspace();
    setWorkspace(data as any);
    setReady(true);
  }, []);

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: workspace?.theme.font?.family,
        },
        palette: {
          ...(workspace?.theme.colors.primary
            ? {
                primary: {
                  main: workspace?.theme.colors.primary,
                },
              }
            : {}),
          ...(workspace?.theme.colors.secondary
            ? {
                secondary: {
                  main: workspace?.theme.colors.secondary,
                },
              }
            : {}),
        },
        shape: {
          borderRadius: 10,
        },
        components: {
          MuiDialog: {
            defaultProps: {
              fullWidth: true,
            },
            styleOverrides: {
              paper: {
                borderRadius: '15px!important',
              },
            },
          },
          MuiInputBase: {
            defaultProps: {
              fullWidth: true,
            },
            styleOverrides: {
              root: {
                backgroundColor: 'transparent!important',
                '&.Mui-focused .Mui-Select': {
                  backgroundColor: 'transparent!important',
                },
              },
            },
          },
          MuiTextField: {
            defaultProps: {
              fullWidth: true,
              variant: 'outlined',
              // size: 'small',
            },
          },
        },
      }),
    [workspace]
  );

  const contextProps = useMemo<WorkspaceContextProps>(
    () => ({
      async reload() {
        await fetchWorkspace();
      },
      schema: workspace as any,
    }),
    [workspace, fetchWorkspace]
  );

  useEffect(() => {
    fetchWorkspace();
  }, [fetchWorkspace]);

  useEffect(() => {
    if (workspace?.theme.font.url) {
      const fontLink = document.createElement('link');
      fontLink.type = 'text/css';
      fontLink.rel = 'stylesheet';
      fontLink.href = workspace?.theme.font?.url;
      document.head.appendChild(fontLink);

      return () => {
        fontLink.remove();
      };
    }
    return () => undefined;
  }, [workspace?.theme.font]);

  useEffect(() => {
    if (workspace?.theme.icon.url) {
      const iconLink = document.createElement('link');
      iconLink.rel = 'shortcut icon';
      iconLink.href = workspace?.theme.icon.url;
      document.head.appendChild(iconLink);
    }
    return () => undefined;
  }, [workspace?.theme.icon.url]);

  useEffect(() => {
    if (workspace) {
      document.title = workspace.theme.title;
      setReady(true);
    }
  }, [workspace]);

  if (!ready)
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'fixed',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <WorkspaceContext.Provider value={contextProps}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </WorkspaceContext.Provider>
  );
};
export default WorkspaceProvider;
