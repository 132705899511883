import React, { useCallback } from 'react';
import { Navigate } from 'react-router-dom';

import { Component, Module } from '@bitpagg/ui-react-core';

// import { getCustomizationProps } from '../helpers/administrative';
import { useWorkspace } from '../hooks/workspace';
import { resources } from '../services/resources';

export default () => {
  const workspace = useWorkspace();

  const KEYCLOAK = {
    provider: {
      type: 'keycloak',
      provider: {
        url: workspace.schema.keycloak.url,
        realm: workspace.schema.keycloak.realm,
        clientId: workspace.schema.keycloak.clientId,
        sessionIdle: workspace.schema.keycloak.sessionIdle,
      },
    },
  };

  // const landing = useMemo<Module.ILandingProps>(
  //   () => getLandingProps(workspace.schema),
  //   [workspace]
  // );

  // const customization = useMemo<Module.IAdministrativeProps['customization']>(
  //   () => getCustomizationProps(workspace.schema),
  //   [workspace]
  // );

  const onCustomizationUpdate = useCallback(() => {
    workspace.reload();
  }, [workspace]);

  return (
    <Module.Root
      keycloak={{
        provider: {
          type: 'keycloak',
          provider: {
            clientId: KEYCLOAK.provider.provider.clientId,
            realm: KEYCLOAK.provider.provider.realm,
            url: KEYCLOAK.provider.provider.url,
            redirectUri: `${window.location.origin}/admin`,
            sessionIdle: KEYCLOAK.provider.provider.sessionIdle, // TODO
          },
        },
      }}
      resources={resources({ onCustomizationUpdate })}
      router={{
        type: 'browser',
      }}
      ui={{
        logo: {
          src: workspace.schema?.theme.logo?.url ?? '',
        },
      }}
    >
      <Component.Router.Route element={<Module.Landing />} path="/*" />
      <Component.Router.Route
        element={<Navigate to="/admin/products" />}
        path="/admin"
      />
      <Component.Router.Route
        element={<Module.Product />}
        path="/admin/products/*"
      />
      <Component.Router.Route
        element={<Module.Setting />}
        path="/admin/settings/*"
      />
    </Module.Root>
  );
};
