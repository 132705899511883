import Http from '../services/http';
import { WorkspaceResponse } from '../services/responses/workspace';

export const getWorkspace = async () => {
  const [keycloakConfiguration, layoutConfiguration] = await Promise.all([
    Http.get<any>('bff-simulefacil/v1/configuracoes-keycloak'),
    Http.get<WorkspaceResponse>(
      'bff-simulefacil/v1/configuracoes-layout/pagina-destino'
    ),
  ]);

  if (
    keycloakConfiguration.status !== 200 ||
    layoutConfiguration.status !== 200
  )
    return null;

  return {
    id: layoutConfiguration.data.id,
    keycloak: {
      url: keycloakConfiguration.data.urlKeyCloak,
      realm: keycloakConfiguration.data.realm,
      clientId: keycloakConfiguration.data.clientId,
      sessionIdle: keycloakConfiguration.data.ssoIdleTime / 60,
    },
    theme: {
      title: layoutConfiguration.data.tema.titulo,
      colors: {
        primary: layoutConfiguration.data.tema.cores.primario,
        secondary: layoutConfiguration.data.tema.cores.secundario,
      },
      logo: {
        url: layoutConfiguration.data.tema.logo.url,
        alt: '',
      },
      icon: {
        url: layoutConfiguration.data.tema.icone.url,
      },
      font: {
        family: layoutConfiguration.data.tema.fonte.familia,
        url: layoutConfiguration.data.tema.fonte.url,
      },
      schema: {
        buttons: {
          social: {
            position: layoutConfiguration.data.tema.esquema.botaoSocial.posicao,
            use: layoutConfiguration.data.tema.esquema.botaoSocial.use,
          },
        },
        hero: {
          use:
            layoutConfiguration.data.tema.esquema.heroi.use ===
            'colunaCabecalhoColunaImagem'
              ? 'columns.header.image'
              : 'image',
          ...(layoutConfiguration.data.tema.esquema.heroi.use ===
          'colunaCabecalhoColunaImagem'
            ? {
                use: 'columns.header.image',
                options: {
                  header: {
                    title: {
                      label:
                        layoutConfiguration.data.tema.esquema.heroi
                          .colunaCabecalhoColunaImagem.titulo.texto,
                    },
                    subtitle: {
                      label:
                        layoutConfiguration.data.tema.esquema.heroi
                          .colunaCabecalhoColunaImagem.subtitulo.texto,
                    },
                  },
                  image: {
                    url: layoutConfiguration.data.tema.esquema.heroi
                      .colunaCabecalhoColunaImagem.fundo.url,
                  },
                },
              }
            : {}),
          ...(layoutConfiguration.data.tema.esquema.heroi.use === 'colunaFundo'
            ? {
                use: 'image',
                options: {
                  image: {
                    url: layoutConfiguration.data.tema.esquema.heroi.colunaFundo
                      .fundo,
                  },
                },
              }
            : {}),
        } as any,
      },
    },
    pages: {
      static: {
        about: {
          menu: {
            label: layoutConfiguration.data.paginas.estaticas.sobre.menu.label,
            visible:
              layoutConfiguration.data.paginas.estaticas.sobre.menu.visivel,
          },
          content: layoutConfiguration.data.paginas.estaticas.sobre.conteudo,
        },
        contact: {
          menu: {
            label:
              layoutConfiguration.data.paginas.estaticas.contato.menu.label,
            visible:
              layoutConfiguration.data.paginas.estaticas.contato.menu.visivel,
          },
          content: {
            email: {
              icon: {
                url: layoutConfiguration.data.paginas.estaticas.contato
                  .informacao.email.icone.url,
              },
              label:
                layoutConfiguration.data.paginas.estaticas.contato.informacao
                  .email.label,
              value:
                layoutConfiguration.data.paginas.estaticas.contato.informacao
                  .email.valor,
            },
            phone: {
              icon: {
                url: layoutConfiguration.data.paginas.estaticas.contato
                  .informacao.telefone.icone.url,
              },
              label:
                layoutConfiguration.data.paginas.estaticas.contato.informacao
                  .telefone.label,
              value:
                layoutConfiguration.data.paginas.estaticas.contato.informacao
                  .telefone.valor,
            },
          },
        },
        product: {
          menu: {
            label:
              layoutConfiguration.data.paginas.estaticas.produto.menu.label,
            visible:
              layoutConfiguration.data.paginas.estaticas.produto.menu.visivel,
          },
          header: {
            title:
              layoutConfiguration.data.paginas.estaticas.produto.cabecalho
                .titulo,
            subtitle:
              layoutConfiguration.data.paginas.estaticas.produto.cabecalho
                .subTitulo,
          },
          sections:
            layoutConfiguration.data.paginas.estaticas.produto.secoes.map(
              (s) => ({
                content: s.conteudo,
              })
            ),
        },
      },
    },
    auth: {
      username: {
        type: 'email',
      },
    },
  };
};
