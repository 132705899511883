import React from 'react';

import WorkspaceProvider from './hooks/workspace';
import Routes from './routes';

const App: React.FC = () => (
  <WorkspaceProvider>
    <Routes />
  </WorkspaceProvider>
);

export default App;
