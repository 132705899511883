import { Context } from '@bitpagg/ui-react-core';
import { IProductSchema } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema';
import { IAssist } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/assist';
import {
  IBlockTicket,
  IBlockTicketRange,
} from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/block-ticket';
import { IBranch } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/branch';
import { IBusinessGroup } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/business-group';
import { ICapitalization } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/capitalization';
import { ICoin } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/coins';
import { ICoverage } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/coverage';
import { IGrouper } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/grouper';
import {
  IPartnerShipType,
  ISalePartner,
} from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/partner';
import { IPaymentPeriodicity } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/payment-periodicity';
import {
  IPrizeTable,
  IPrizeTableRange,
  IPrizeTableVariantType,
} from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/prize-table';
import { IProduct } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/product';
import { ISaleLimit } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/sale-limit';
import {
  IAliquotTypes,
  ITaxTypes,
} from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/tax';
import { IValidity } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/validity';
import { IVariantSimple } from '@bitpagg/ui-react-core/dist/contexts/resource/product-schema/interfaces/variant';

import { IAssistencia } from './interfaces/assist';
import {
  IBlocoBilheteRetorno,
  IBlocoBilheteRetornoItem,
  IBlocoNumeracaoBilhete,
} from './interfaces/block-ticket';
import { IGrupoEmpresarial } from './interfaces/business-group';
import {
  ICapitalizacao,
  ICapitalizacaoLista,
  ICapitalizacaoRetorno,
} from './interfaces/capitalization';
import { ICobertura } from './interfaces/coverage';
import { ITipoCobertura } from './interfaces/coverage-type';
import { IModalidadeSorteio } from './interfaces/draw-modality';
import { IVinculoCobertura } from './interfaces/link-coverage';
import { IPeriodicidadePagamento } from './interfaces/payment-periodicity';
import { TabelaPremio } from './interfaces/prize-table';
import { IProdutosPaginados } from './interfaces/product';
import { IPeriodicidadeSorteio } from './interfaces/raffle-periodicity';
import { ITipoRisco } from './interfaces/risk-type';
import { ITipoStatusVenda } from './interfaces/sale-limit';
import { ITipoAliquota, ITipoImposto } from './interfaces/tax';
import { ITipoBlocoNumeracaoBilhete } from './interfaces/ticket-block-type';
import { IInicioVigenciaProduto } from './interfaces/validity';
import { IVariante, IVarianteSimples } from './interfaces/variant';

export const product = (): IProductSchema => ({
  async createPrizeTable(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/tabela-premio`,
      [
        {
          idVariante: payload.variantId,
          nome: payload.name,
          tipoVariacao: payload.type.id,
          dataInicioValidade: payload.startDate,
          dataFimValidade: payload.endDate,
        },
      ]
    );

    if (status === 201) {
      return {
        status: 'OK',
        payload: {
          id: data[0].id,
          name: data[0].nome,
          type: {
            id: data[0].tipoVariacao,
            name: data[0].tipoVariacao,
          },
          variantId: payload.variantId,
          startDate: data[0].dataInicioValidade,
          endDate: data[0].dataFimValidade,
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async updatePrizeTable(id, payload) {
    const { data, status } = await Context.Resource.http.put(
      `bff-simulefacil/v1/tabela-premio/${id}`,
      {
        idVariante: payload.variantId,
        nome: payload.name,
        tipoVariacao: payload.type.id,
        dataInicioValidade: payload.startDate,
        dataFimValidade: payload.endDate,
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          id: data.id,
          name: data.nome,
          type: {
            id: data.tipoVariacao,
            name: data.tipoVariacao,
          },
          variantId: payload.variantId,
          startDate: data.dataInicioValidade,
          endDate: data.dataFimValidade,
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async deletePrizeTable(id) {
    const { data, status } = await Context.Resource.http.delete(
      `bff-simulefacil/v1/tabela-premio/${id}`
    );
    if (status === 204) {
      return {
        status: 'OK',
        payload: true,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem,
    };
  },
  async getProductOptions() {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/...`
    );
    if (status === 200) {
      return {
        status: 'OK',
        payload: {} as any,
      };
    }
    return {
      status: 'FAIL',
      message: data.mensagem as string,
    };
  },
  async deleteProduct(id) {
    const { data, status } = await Context.Resource.http.delete(
      `bff-simulefacil/v1/produto/${id}`
    );
    if (status === 204) {
      return {
        status: 'OK',
        payload: true,
      };
    }
    return {
      status: 'FAIL',
      message: data.mensagem,
    };
  },

  async getPrizeTableById(id) {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/tabela-premio/${id}`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          id: data.id,
          name: data.nome,
          type: {
            id: data.tipoVariacao,
            name: data.tipoVariacao,
          },
          variantId: data.idVariante,
          startDate: data.dataInicioValidade,
          endDate: data.dataFimValidade,
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getPrizeTableVariantTypes() {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/tabela-premio/tipos-variacao`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ idTipoVariacao, descricaoTipoVariacao }: any) =>
            <IPrizeTableVariantType>{
              id: idTipoVariacao,
              name: descricaoTipoVariacao,
            }
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async downloadBlockTicketRanges(prizeTableId) {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/importar-exportar/excel/taxas-cadastradas/${prizeTableId}`,
      {
        responseType: 'blob',
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          file: data,
          filename: 'modelo.xlsx',
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getPrizeTableRanges(id, query) {
    const { status, data } = await Context.Resource.http.get(
      `bff-simulefacil/v1/taxa-premio-cobertura-idade/coberturas-idade/${id}`,
      {
        params: {
          pagina: query.page,
          elementosPorPagina: 10,
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          items: (data.taxas ?? []).map(
            ({ nomeCobertura, idade, taxa }: any) =>
              <IPrizeTableRange>{
                coverageName: nomeCobertura,
                age: idade,
                tax: {
                  type: 'percentage',
                  value: taxa,
                },
              }
          ),
          meta: {
            limit: data.metadata?.limitePorPagina ?? 0,
            page: data.metadata?.pagina ?? 1,
            pages: data.metadata?.totalPaginas ?? 0,
            totalItems: data.metadata?.totalItems ?? 0,
          },
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getBlockTicketRanges(id, query) {
    const { status, data } = await Context.Resource.http.get(
      `bff-simulefacil/v1/bloco-numeracao-bilhete/faixa/${id}`,
      {
        params: {
          pagina: query.page,
          elementosPorPagina: 10,
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          items: data.faixas.map(
            ({
              nomeFaixaNumeracao,
              dataFimValidade,
              dataInicioValidade,
            }: any) =>
              <IBlockTicketRange>{
                name: nomeFaixaNumeracao,
                startDate: dataInicioValidade,
                endDate: dataFimValidade,
              }
          ),
          meta: {
            limit: data.metadata.limitePorPagina,
            page: data.metadata.pagina,
            pages: data.metadata.totalPaginas,
            totalItems: data.metadata.totalItems,
          },
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getCapitalizationsWithPagination({ page }) {
    const { status, data } = await Context.Resource.http.get(
      `bff-simulefacil/v1/capitalizacao`,
      {
        params: {
          pagina: page ?? 1,
          elementosPorPagina: 10,
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          items: data.capitalizacoes.map((c: ICapitalizacaoRetorno) => ({
            id: c.id,
            name: c.nomeComercial,
          })),
          meta: {
            page: data?.metadata?.pagina ?? 1,
            pages: data?.metadata?.totalPaginas ?? 1,
            limit: data?.metadata?.limitePorPagina ?? 10,
            totalItems: data?.metadata?.totalPaginas ?? 1,
          },
        },
      };
    }
    return {
      status: 'FAIL',
      payload: undefined,
    };
  },
  async getBlockTicketsWithPagination({ page }) {
    const { status, data } = await Context.Resource.http.get(
      `bff-simulefacil/v1/bloco-numeracao-bilhete`,
      {
        params: {
          pagina: page ?? 1,
          elementosPorPagina: 10,
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          items: data.blocoNumeracaoBilhete.map(
            (blockTicket: IBlocoBilheteRetorno) => ({
              id: blockTicket.idBlocoNumeracaoBilhete,
              blockName: blockTicket.nomeBlocoNumeracaoBilhete,
            })
          ),
          meta: {
            page: data?.metadata?.pagina ?? 1,
            pages: data?.metadata?.totalPaginas ?? 1,
            limit: data?.metadata?.limitePorPagina ?? 100,
            totalItems: data?.metadata?.totalPaginas ?? 1,
          },
        },
      };
    }
    return {
      status: 'FAIL',
      payload: undefined,
    };
  },
  async createBlockTicket(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/bloco-numeracao-bilhete`,
      {
        id: null,
        idProduto: null,
        nomeBlocoNumeracaoBilhete: payload.blockName,
        tipoNumeracaoBilhete: payload.blockType?.id,
        faixasNumeracao: payload?.ranges?.map(
          ({ endDate, startDate, nameRange, numbers }) => ({
            idBlocoFaixaNumeracao: null,
            idBlocoNumeracaoBilhete: null,
            nomeFaixaNumeracao: nameRange,
            dataInicioValidade: startDate,
            dataFimValidade: endDate,
            numeracaoManual: numbers?.map(({ number, luckyNumber }) => ({
              idFaixaNumeracao: null,
              statusNumeracaoManual: 'N',
              numeroManualProposta: number.toString(),
              numeroSorteCapitalizacao: luckyNumber.toString(),
            })),
          })
        ),
      }
    );

    if (status === 201) {
      return {
        status: 'OK',
        payload: data[0],
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem,
    };
  },
  async getBlockTicketById(id) {
    const { data, status } =
      await Context.Resource.http.get<IBlocoBilheteRetornoItem>(
        `bff-simulefacil/v1/bloco-numeracao-bilhete/${id}`
      );
    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          id: data?.idBlocoNumeracaoBilhete,
          blockName: data?.nomeBlocoNumeracaoBilhete,
          blockType: {
            id: data?.tipoNumeracaoBilhete,
          },
          ranges: data?.faixasNumeracao
            ? data?.faixasNumeracao?.map(
                ({
                  dataInicioValidade,
                  dataFimValidade,
                  nomeFaixaNumeracao,
                  numeracaoManual,
                  idBlocoFaixaNumeracao,
                  idBlocoNumeracaoBilhete,
                }) => ({
                  idBlockRange: idBlocoFaixaNumeracao,
                  idBlockNumber: idBlocoNumeracaoBilhete,
                  nameRange: nomeFaixaNumeracao,
                  numbers: numeracaoManual
                    ? numeracaoManual?.map(
                        ({
                          numeroManualProposta,
                          numeroSorteCapitalizacao,
                          idFaixaNumeracao,
                          statusNumeracaoManual,
                        }) => ({
                          idRange: idFaixaNumeracao,
                          number: numeroManualProposta,
                          luckyNumber: numeroSorteCapitalizacao,
                          status: statusNumeracaoManual,
                        })
                      )
                    : [],
                  startDate: new Date(dataInicioValidade),
                  endDate: new Date(dataFimValidade),
                })
              )
            : [],
        } as any,
      };
    }
    return {
      status: 'FAIL',
      payload: undefined,
    };
  },
  async getBlockTicketsByProduct() {
    return {
      status: 'FAIL',
    };
  },
  async updateBlockTicket(id, payload) {
    const { data, status } = await Context.Resource.http.put(
      `bff-simulefacil/v1/bloco-numeracao-bilhete/${id}`,
      {
        idBlocoNumeracaoBilhete: id,
        idProduto: null,
        nomeBlocoNumeracaoBilhete: payload.blockName,
        tipoNumeracaoBilhete: payload.blockType?.id,
        faixasNumeracao: payload?.ranges?.map(
          ({
            endDate,
            startDate,
            nameRange,
            numbers,
            idBlockNumber,
            idBlockRange,
          }) => ({
            idBlocoFaixaNumeracao: idBlockRange,
            idBlocoNumeracaoBilhete: idBlockNumber,
            nomeFaixaNumeracao: nameRange,
            dataInicioValidade: startDate,
            dataFimValidade: endDate,
            numeracaoManual: numbers?.map(
              ({ number, luckyNumber, idRange, status: statusNumber }) => ({
                idFaixaNumeracao: idRange ?? idBlockRange,
                statusNumeracaoManual: statusNumber ?? 'N',
                numeroManualProposta: number.toString(),
                numeroSorteCapitalizacao: luckyNumber.toString(),
              })
            ),
          })
        ),
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
      message: data?.mensagem,
    };
  },
  async deleteBlockTicket(id) {
    const { data, status } = await Context.Resource.http.delete(
      `bff-simulefacil/v1/bloco-numeracao-bilhete/${id}`
    );
    if (status === 204) {
      return {
        status: 'OK',
        payload: true,
      };
    }
    return {
      status: 'FAIL',
      message: data.mensagem,
    };
  },
  async getTicketBlockTypes() {
    const { status, data } = await Context.Resource.http.get<
      ITipoBlocoNumeracaoBilhete[]
    >(`bff-simulefacil/v1/bloco-numeracao-bilhete/tipos-numeracao-bilhete`);
    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ idTipoNumeracaoBilhete, descricaoTipoVinculo }) => ({
            id: idTipoNumeracaoBilhete,
            name: descricaoTipoVinculo,
          })
        ) as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async createCapitalization(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/capitalizacao`,
      [
        {
          idProduto: payload.productId,
          nomeComercial: payload.name,
          controleNumeracao: payload.drawModality.id,
          valorPremio: payload.premiumCapitalValue,
          periodicidadeSorteio: payload.raffleFrequency.id,
          textoExibicaoPrincipal: payload?.exhibitionTexts?.main,
          textoExibicaoResumo: payload?.exhibitionTexts?.saleSummary,
          textoImpressaoProposta: payload?.exhibitionTexts?.proposedPrint,
        },
      ]
    );

    if (status === 201) {
      return {
        status: 'OK',
        payload: data[0],
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async updateCapitalization(id, payload) {
    const { data, status } = await Context.Resource.http.put(
      `bff-simulefacil/v1/capitalizacao/${id}`,
      {
        id,
        nomeComercial: payload.name,
        controleNumeracao: payload.drawModality.id,
        valorPremio: payload.premiumCapitalValue,
        periodicidadeSorteio: payload.raffleFrequency.id,
        textoExibicaoPrincipal: payload?.exhibitionTexts?.main,
        textoExibicaoResumo: payload?.exhibitionTexts?.saleSummary,
        textoImpressaoProposta: payload?.exhibitionTexts?.proposedPrint,
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async deleteCapitalization(id) {
    const { data, status } = await Context.Resource.http.delete(
      `bff-simulefacil/v1/capitalizacao/${id}`
    );

    if (status === 204) {
      return {
        status: 'OK',
        payload: true,
      };
    }
    return {
      status: 'FAIL',
      message: data.mensagem,
    };
  },
  async getCapitalizationById(id) {
    const { data, status } = await Context.Resource.http.get<ICapitalizacao>(
      `bff-simulefacil/v1/capitalizacao/${id}`
    );
    if (status === 200) {
      return {
        status: 'OK',
        payload: (<ICapitalization>{
          id: data.id,
          name: data.nomeComercial,
          raffleFrequency: {
            id: data.periodicidadeSorteio,
            description: data.descricaoPeriodicidadeSorteio,
          },
          drawModality: {
            id: data.controleNumeracao,
            description: data.descricaoControleNumeracao,
          },
          exhibitionTexts: {
            main: data.textoExibicaoPrincipal,
            proposedPrint: data.textoImpressaoProposta,
            saleSummary: data.textoExibicaoResumo,
          },
          premiumCapitalValue: data.valorPremio,
        }) as any,
      };
    }
    return {
      status: 'FAIL',
      payload: undefined,
    };
  },
  async getCapitalizationByProduct(productId) {
    const { data, status } = await Context.Resource.http.get<ICapitalizacao>(
      `bff-simulefacil/v1/capitalizacao/produto/${productId}`
    );

    if (status === 200 && !Array.isArray(data)) {
      return {
        status: 'OK',
        payload: {
          id: data.id,
          productId,
          name: data.nomeComercial,
          raffleFrequency: {
            id: data.periodicidadeSorteio,
            description: data.descricaoPeriodicidadeSorteio,
          },
          drawModality: {
            id: data.controleNumeracao,
            description: data.descricaoControleNumeracao,
          },
          exhibitionTexts: {
            main: data.textoExibicaoPrincipal,
            proposedPrint: data.textoImpressaoProposta,
            saleSummary: data.textoExibicaoResumo,
          },
          premiumCapitalValue: data.valorPremio,
        } as ICapitalization,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getDrawModalities() {
    const { data, status } = await Context.Resource.http.get<
      IModalidadeSorteio[]
    >(`bff-simulefacil/v1/capitalizacao/modalidades-sorteio`);

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(({ idPeriodicidade, descricaoPeriodicidade }) => ({
          id: idPeriodicidade,
          description: descricaoPeriodicidade,
        })),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getRafflePeriodicities() {
    const { data, status } = await Context.Resource.http.get<
      IPeriodicidadeSorteio[]
    >(`bff-simulefacil/v1/capitalizacao/periodicidades-sorteio`);

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(({ idPeriodicidade, descricaoPeriodicidade }) => ({
          id: idPeriodicidade,
          description: descricaoPeriodicidade,
        })),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getAssistByVariant(variantId: string) {
    const { data, status } = await Context.Resource.http.get<IAssistencia[]>(
      `bff-simulefacil/v1/assistencia/variante-produto/${variantId}`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: (data ?? []).map(
          (assist) =>
            <IAssist>{
              id: assist.id,
              startAt:
                !!assist.dataInicioPermitidoVenda &&
                new Date(assist.dataInicioPermitidoVenda),
              endAt:
                !!assist.dataFimPermitidoVenda &&
                new Date(assist.dataFimPermitidoVenda),
              internalName: assist.nomeInterno,
              name: assist.nomeComercial,
              order: assist.ordemExibicao,
              variantId: assist.idVariante,
              mandatoryForProduct: assist.contratacaoObrigatoria === 'S',
              exhibitionTexts: {
                main: assist?.textosAssistencia?.textoExibicaoPrincipal ?? null,
                aditional:
                  assist?.textosAssistencia?.textoExibicaoAdicional ?? null,
                complement:
                  assist?.textosAssistencia?.textoExibicaoComplemento ?? null,
                gracePeriod:
                  assist?.textosAssistencia?.textoExibicaoCarencia ?? null,
                proposedPrint:
                  assist?.textosAssistencia?.textoImpressaoProposta ?? null,
                saleSummary:
                  assist?.textosAssistencia?.textoExibicaoResumoVenda ?? null,
              },
              partnerCodes: assist.assistenciaIdentificacaoGrupos.map(
                ({ grupoEmpresarial, idParceiro }) => ({
                  group: {
                    id: grupoEmpresarial.id,
                    name: grupoEmpresarial.nome,
                  },
                  partner: {
                    id: grupoEmpresarial.idParceiro,
                    name: grupoEmpresarial.nomeParceiro,
                  },
                  partnerAssistId: idParceiro,
                })
              ),
            }
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async deleteAssist(id) {
    const { data, status } = await Context.Resource.http.delete(
      `bff-simulefacil/v1/assistencia/${id}`
    );

    if (status === 204) {
      return {
        status: 'OK',
        payload: true,
      };
    }
    return {
      status: 'FAIL',
      payload: false,
      message: data?.mensagem,
    };
  },
  async getAssistById(id) {
    const { data, status } = await Context.Resource.http.get<IAssistencia>(
      `bff-simulefacil/v1/assistencia/${id}`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: (<IAssist>{
          variantId: data.idVariante,
          id: data.id,
          startAt:
            !!data.dataInicioPermitidoVenda &&
            new Date(data.dataInicioPermitidoVenda),
          endAt:
            !!data.dataFimPermitidoVenda &&
            new Date(data.dataFimPermitidoVenda),
          internalName: data.nomeInterno,
          name: data.nomeComercial,
          order: data.ordemExibicao ?? 1,
          mandatoryForProduct: data.contratacaoObrigatoria === 'S',
          exhibitionTexts: {
            main: data?.textosAssistencia?.textoExibicaoPrincipal ?? null,
            aditional: data?.textosAssistencia?.textoExibicaoAdicional ?? null,
            complement:
              data?.textosAssistencia?.textoExibicaoComplemento ?? null,
            gracePeriod: data?.textosAssistencia?.textoExibicaoCarencia ?? null,
            proposedPrint:
              data?.textosAssistencia?.textoImpressaoProposta ?? null,
            saleSummary:
              data?.textosAssistencia?.textoExibicaoResumoVenda ?? null,
          },
          partnerCodes: (data.assistenciaIdentificacaoGrupos ?? []).map(
            ({ grupoEmpresarial, idParceiro }) => ({
              group: {
                id: grupoEmpresarial.id,
                name: grupoEmpresarial.nome,
              },
              partner: {
                id: grupoEmpresarial.idParceiro,
                name: grupoEmpresarial.nomeParceiro,
              },
              partnerAssistId: idParceiro,
            })
          ),
        }) as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async updateAssist(id, payload) {
    const { data, status } = await Context.Resource.http.put(
      `bff-simulefacil/v1/assistencia/${id}`,
      {
        id,
        idVariante: payload.variantId,
        nomeInterno: payload.internalName,
        nomeComercial: payload.name,
        ordemExibicao: payload.order,
        contratacaoObrigatoria: payload.mandatoryForProduct ? 'S' : 'N',
        dataInicioPermitidoVenda: payload.startAt,
        dataFimPermitidoVenda: payload.endAt,
        textosAssistencia: {
          textoExibicaoPrincipal: payload.exhibitionTexts.main,
          textoExibicaoComplemento: payload.exhibitionTexts.complement,
          textoExibicaoAdicional: payload.exhibitionTexts.complement,
          textoExibicaoResumoVenda: payload.exhibitionTexts.saleSummary,
          textoExibicaoCarencia: payload.exhibitionTexts.gracePeriod,
          textoImpressaoProposta: payload.exhibitionTexts.proposedPrint,
        },
        assistenciaIdentificacaoGrupos: (payload.partnerCodes ?? []).map(
          (partnerCode) => ({
            idGrupoEmpresarial: partnerCode.group.id,
            idParceiro: partnerCode.partnerAssistId,
          })
        ),
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          id: data.id,
        } as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async createAssist(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/assistencia`,
      [
        {
          idVariante: payload.variantId,
          nomeInterno: payload.internalName,
          nomeComercial: payload.name,
          ordemExibicao: 1,
          contratacaoObrigatoria: payload.mandatoryForProduct ? 'S' : 'N',
          dataInicioPermitidoVenda: payload.startAt,
          dataFimPermitidoVenda: payload.endAt,
          textosAssistencia: {
            textoExibicaoPrincipal: payload.exhibitionTexts.main,
            textoExibicaoComplemento: payload.exhibitionTexts.complement,
            textoExibicaoAdicional: payload.exhibitionTexts.complement,
            textoExibicaoResumoVenda: payload.exhibitionTexts.saleSummary,
            textoExibicaoCarencia: payload.exhibitionTexts.gracePeriod,
            textoImpressaoProposta: payload.exhibitionTexts.proposedPrint,
          },
          assistenciaIdentificacaoGrupos: (payload.partnerCodes ?? []).map(
            (partnerCode) => ({
              idGrupoEmpresarial: partnerCode.group.id,
              idParceiro: partnerCode.partnerAssistId,
            })
          ),
        },
      ]
    );

    if (status === 201) {
      return {
        status: 'OK',
        payload: { id: data.id, name: data.nome } as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async deleteCoverage(id) {
    const { data, status } = await Context.Resource.http.delete(
      `bff-simulefacil/v1/cobertura/${id}`
    );

    if (status === 204) {
      return {
        status: 'OK',
        payload: true,
      };
    }
    return {
      status: 'FAIL',
      payload: false,
      message: data?.mensagem,
    };
  },
  async getCoverageLinks() {
    const { data, status } = await Context.Resource.http.get<
      IVinculoCobertura[]
    >(
      `bff-simulefacil/v1/vinculo-cobertura-basica/coberturas-vinculadas/tipos-vinculo`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(({ descricaoTipoVinculo, idTipoVinculo }) => ({
          id: idTipoVinculo,
          description: descricaoTipoVinculo,
        })),
      } as any;
    }
    return {
      status: 'FAIL',
    };
  },
  async createCoverage(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/cobertura`,
      [
        {
          ProdutoHabilitadoParaVenda: payload.enabledSales ?? false,
          idVariante: payload.variantId,
          idTipoRisco: payload.linkedRiskType?.id ?? null,
          nomeInterno: payload.internalName,
          nomeComercial: payload.commercialName,
          tipoPrecificacao:
            payload.pricing?.type === 'fixed'
              ? 'F'
              : payload.pricing?.type === 'table'
              ? 'T'
              : null,
          tipoBase: payload.baseType === 'basic' ? 'B' : 'A',
          tipo: payload.type?.id ?? null,
          ordemExibicao: 1,
          contratacaoObrigatoria: payload.mandatoryCoverageForProduct
            ? 'S'
            : 'N',
          dataInicioPermitidoVenda: payload?.startAt ?? null,
          dataFimPermitidoVenda: payload?.endAt ?? null,
          idadeMinima: payload?.hiringAgeLimit?.min ?? null,
          idadeMaxima: payload?.hiringAgeLimit?.max ?? null,
          tipoContratacaoCapital:
            payload?.capitalContracting?.type === 'fixed'
              ? 'F'
              : payload?.capitalContracting?.type === 'variable'
              ? 'V'
              : null,
          limiteMinimoCapitalSegurado:
            payload?.capitalContracting?.minimumCapital ?? null,
          limiteMaximoCapitalSegurado:
            payload?.capitalContracting?.maximumCapital ?? null,
          intervaloCapitalSegurado:
            payload?.capitalContracting?.hiringCapitalRange ?? null,
          valorCapitalSeguradoFixo:
            payload?.capitalContracting?.insuredCapital ?? null,
          ...(payload.baseType !== 'basic'
            ? {
                coberturasVinculadas: [
                  {
                    idCoberturaVinculada: null,
                    idCoberturaReferencia:
                      payload.basicCoverageLimit?.reference ?? null,
                    percentualMinimo: payload.basicCoverageLimit?.min ?? null,
                    percentualMaximo: payload.basicCoverageLimit?.max ?? null,
                    passoPercentual:
                      payload.basicCoverageLimit?.interval ?? null,
                  },
                ],
              }
            : {}),
          ...(payload.baseType !== 'basic'
            ? {
                coberturasInclusasOuExlusas: (
                  payload.coveragesIncludedOrExcluded ?? []
                ).map(({ linkType, linkedCoverageReference }) => ({
                  idCoberturaVinculada: linkedCoverageReference.id,
                  tipoVinculo: linkType.id,
                })),
              }
            : {}),
          textosCobertura: {
            textoExibicaoPrincipal: payload.exhibitionTexts?.main,
            textoExibicaoComplemento: payload.exhibitionTexts?.complement,
            textoExibicaoAdicional: payload.exhibitionTexts?.aditional,
            textoExibicaoResumoVenda: payload.exhibitionTexts?.saleSummary,
            textoExibicaoCarencia: payload.exhibitionTexts?.gracePeriod,
            textoExibicaoProposta: payload.exhibitionTexts?.proposedPrint,
          },
          coberturaIdentificacaoGrupos: (payload.partnerCodes ?? []).map(
            (partnerCode) => ({
              idGrupoEmpresarial: partnerCode.group.id,
              idParceiro: partnerCode.partnerCoverageId,
            })
          ),
        },
      ]
    );

    if (status === 201) {
      return {
        status: 'OK',
        payload: { id: data.id, name: data.nomeInterno } as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async createVariant(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/variante-produto`,
      [
        {
          id: null,
          idProduto: payload.productId,
          nomeInterno: payload.internalName,
          nomeComercial: payload.commercialName,
          habilitadoParaVenda: payload.enabledSale ? 'S' : 'N',
          tipoDuracaoVigencia:
            payload.validity.durationType === 'months'
              ? 'M'
              : payload.validity.durationType === 'years'
              ? 'A'
              : 'R',
          valorDuracaoVigencia:
            payload.validity.durationType === 'months'
              ? payload.validity.durationValueMonth
              : payload.validity.durationType === 'years'
              ? payload.validity.durationValueYear
              : null,
          tipoInicioVigencia: payload.validity.validityStartType.id,
          valorPremioBrutoMinimo: payload.grossMinimumPremiumValue,
          tipoPrecificacao: payload.pricing.type === 'fixed' ? 'F' : 'C',
          valorPremioBrutoFixo: payload.pricing.fixedGrossPremiumValue,
          restricaoVendaIdade: {
            idVariante: null,
            idadeMinima: payload.ageSaleRestriction.min,
            idadeMaxima: payload.ageSaleRestriction.max,
          },
          restricaoHistoricoVenda: {
            idVariante: null,
            statusVenda: payload.saleRestriction.salesStatus.id,
            numeroVendas: payload.saleRestriction.numberOfSales,
          },
          limitesContratacao: {
            idVariante: null,
            minimoBeneficiarios: payload.beneficiaries.min,
            maximoBeneficiarios: payload.beneficiaries.max,
          },
          gruposDeAcessoVariante: [
            {
              idVarianteAcesso: null,
              idGrupoEmpresarial: null,
              flagConsiderarDescendentes: null,
            },
          ],
          identificadoresGrupoVariante: (payload.partnerCodes ?? []).map(
            (partnerCode) => ({
              idVariacaoProduto: null,
              idGrupoEmpresarial: partnerCode.group.id,
              idParceiroProduto: partnerCode.partnerCoverageId,
            })
          ),
          aliquotasImposto: (payload.taxRates ?? []).map((taxRate) => ({
            idVarianteProduto: null,
            tipoImpostoSigla: taxRate.typeTaxRate.id,
            tipoAliquota: taxRate.typeAliquot.id,
            valorAliquota: taxRate.valueAliquot,
            siglaValorAliquota: 'BRL',
          })),
          periodicidadePagamento: {
            idProdutoVariacao: null,
            tipoPeriodicidadePagamentos: (
              payload.paymentPeriodicities ?? []
            ).map((paymentPeriodicity) => ({
              id: paymentPeriodicity?.id,
              nome: paymentPeriodicity?.name,
            })),
          },
        },
      ]
    );

    if (status === 201) {
      return {
        status: 'OK',
        payload: data[0] as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getCoverageTypes() {
    const { data, status } = await Context.Resource.http.get<ITipoCobertura[]>(
      `bff-simulefacil/v1/cobertura/tipos-cobertura`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(({ id, descricao }) => ({
          id,
          description: descricao,
        })) as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getBusinessGroups() {
    const { data, status } = await Context.Resource.http.get<
      IGrupoEmpresarial[]
    >(`bff-simulefacil/v1/grupo-empresarial`);

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({
            id,
            nome,
            hierarquia,
            idParceiro,
            idGrupoNoParceiro,
            grupoEmpresarialFilhos,
          }) => ({
            id,
            name: nome,
            hierarchy: hierarquia,
            partner: {
              id: idParceiro,
            },
            partnerGroup: {
              id: idGrupoNoParceiro,
            },
            children: (grupoEmpresarialFilhos ?? []).map(
              (grupoEmpresarial) => ({
                id: grupoEmpresarial.id,
                name: grupoEmpresarial.nome,
                hierarchy: grupoEmpresarial.hierarquia,
                partner: {
                  id: grupoEmpresarial.idParceiro,
                },
                partnerGroup: {
                  id: grupoEmpresarial.idGrupoNoParceiro,
                },
              })
            ),
          })
        ) as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async updateCoverage(id, payload) {
    const { data, status } = await Context.Resource.http.put(
      `bff-simulefacil/v1/cobertura/${id}`,
      {
        id,
        idVariante: payload.variantId,
        ProdutoHabilitadoParaVenda: payload.enabledSales ?? false,
        idTipoRisco: payload.linkedRiskType?.id ?? null,
        nomeInterno: payload.internalName,
        nomeComercial: payload.commercialName,
        tipoPrecificacao:
          payload.pricing?.type === 'fixed'
            ? 'F'
            : payload.pricing?.type === 'table'
            ? 'T'
            : null,
        tipoBase: payload.baseType === 'basic' ? 'B' : 'A',
        tipo: payload.type?.id ?? null,
        ordemExibicao: 0,
        contratacaoObrigatoria: payload.mandatoryCoverageForProduct ? 'S' : 'N',
        dataInicioPermitidoVenda: payload?.startAt ?? null,
        dataFimPermitidoVenda: payload?.endAt ?? null,
        idadeMinima: payload?.hiringAgeLimit?.min ?? null,
        idadeMaxima: payload?.hiringAgeLimit?.max ?? null,
        tipoContratacaoCapital:
          payload?.capitalContracting?.type === 'fixed'
            ? 'F'
            : payload?.capitalContracting?.type === 'variable'
            ? 'V'
            : null,
        limiteMinimoCapitalSegurado:
          payload?.capitalContracting?.minimumCapital ?? null,
        limiteMaximoCapitalSegurado:
          payload?.capitalContracting?.maximumCapital ?? null,
        intervaloCapitalSegurado:
          payload?.capitalContracting?.hiringCapitalRange ?? null,
        valorCapitalSeguradoFixo:
          payload?.capitalContracting?.insuredCapital ?? null,
        ...(payload.baseType !== 'basic'
          ? {
              coberturasVinculadas: [
                {
                  idCoberturaVinculada: null,
                  idCoberturaReferencia:
                    payload.basicCoverageLimit?.reference ?? null,
                  percentualMinimo: payload.basicCoverageLimit?.min ?? null,
                  percentualMaximo: payload.basicCoverageLimit?.max ?? null,
                  passoPercentual: payload.basicCoverageLimit?.interval ?? null,
                },
              ],
            }
          : {}),
        ...(payload.baseType !== 'basic'
          ? {
              coberturasInclusasOuExlusas: (
                payload.coveragesIncludedOrExcluded ?? []
              ).map(({ linkType, linkedCoverageReference }) => ({
                idCoberturaVinculada: linkedCoverageReference.id,
                tipoVinculo: linkType.id,
              })),
            }
          : {}),
        textosCobertura: {
          textoExibicaoPrincipal: payload.exhibitionTexts?.main,
          textoExibicaoComplemento: payload.exhibitionTexts?.complement,
          textoExibicaoAdicional: payload.exhibitionTexts?.aditional,
          textoExibicaoResumoVenda: payload.exhibitionTexts?.saleSummary,
          textoExibicaoCarencia: payload.exhibitionTexts?.gracePeriod,
          textoExibicaoProposta: payload.exhibitionTexts?.proposedPrint,
        },
        coberturaIdentificacaoGrupos: (payload.partnerCodes ?? []).map(
          (partnerCode: any) => ({
            // idCobertura: partnerCode.partnerCoverageId,
            idGrupoEmpresarial: partnerCode.group.id,
            idParceiro: partnerCode.partnerCoverageId,
          })
        ),
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          id: data.id,
        } as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getCoverageById(id) {
    const { data, status } = await Context.Resource.http.get<ICobertura>(
      `bff-simulefacil/v1/cobertura/${id}`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: (<ICoverage>{
          id: data.id,
          commercialName: data.nomeComercial,
          internalName: data.nomeInterno,
          baseType: data.tipoBase,
          order: data.ordemExibicao,
          type: {
            id: data.tipo,
            description: data.tipo,
          },
          basicCoverageLimit: {
            interval:
              data.coberturasVinculadas?.[0]?.passoPercentual ?? undefined,
            max: data.coberturasVinculadas?.[0]?.percentualMaximo ?? undefined,
            min: data.coberturasVinculadas?.[0]?.percentualMinimo ?? undefined,
            reference:
              data.coberturasVinculadas?.[0]?.idCoberturaReferencia ??
              undefined,
          },
          capitalContracting: {
            type:
              data.tipoContratacaoCapital === 'F'
                ? 'fixed'
                : data.tipoContratacaoCapital === 'V'
                ? 'variable'
                : null,
            hiringCapitalRange: data.intervaloCapitalSegurado ?? undefined,
            insuredCapital: data.valorCapitalSeguradoFixo ?? undefined,
            minimumCapital: data.limiteMinimoCapitalSegurado ?? undefined,
            maximumCapital: data.limiteMaximoCapitalSegurado ?? undefined,
          },
          startAt: new Date(data.dataInicioPermitidoVenda),
          endAt: new Date(data.dataFimPermitidoVenda),
          hiringAgeLimit: {
            max: data.idadeMaxima,
            min: data.idadeMinima,
          },
          linkedRiskType: {
            id: data.idTipoRisco,
            description: data.idTipoRisco,
          },
          mandatoryCoverageForProduct: data.contratacaoObrigatoria === 'S',
          partnerCodes: (data.coberturaIdentificacaoGrupos ?? []).map(
            ({ grupoEmpresarial, idParceiro, nomeParceiro }) => ({
              partnerCoverageId: idParceiro,
              group: {
                id: grupoEmpresarial.id,
                name: grupoEmpresarial.nome,
              },
              partner: {
                id: grupoEmpresarial.idParceiro,
                name: nomeParceiro,
              },
            })
          ),
          pricing: {
            type:
              data.tipoPrecificacao === 'T'
                ? 'table'
                : data.tipoPrecificacao === 'F'
                ? 'table'
                : null,
          },
          exhibitionTexts: {
            main: data.textosCobertura?.textoExibicaoPrincipal,
            aditional: data.textosCobertura?.textoExibicaoAdicional,
            complement: data.textosCobertura?.textoExibicaoComplemento,
            gracePeriod: data.textosCobertura?.textoExibicaoCarencia,
            proposedPrint: data.textosCobertura?.textoExibicaoProposta,
            saleSummary: data.textosCobertura?.textoExibicaoResumoVenda,
          },
          coveragesIncludedOrExcluded: (
            data.coberturasInclusasOuExlusas ?? []
          ).map(
            ({
              idCoberturaVinculada,
              nomeCoberturaVinculada,
              tipoVinculo,
              descricaoTipoVinculo,
            }) => ({
              linkedCoverageReference: {
                id: idCoberturaVinculada,
                name: nomeCoberturaVinculada,
              },
              linkType: {
                id: tipoVinculo,
                name: descricaoTipoVinculo,
              },
            })
          ),
        }) as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getCoveragesByVariant(variantId: string) {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/cobertura/variante-produto/${variantId}`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ id, nomeInterno, ordemExibicao, tipoBase }: any) =>
            <ICoverage>{
              id,
              internalName: nomeInterno,
              order: ordemExibicao,
              baseType: tipoBase === 'B' ? 'basic' : 'additional',
            }
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getVariant(id) {
    const { data, status } = await Context.Resource.http.get<IVariante>(
      `bff-simulefacil/v1/variante-produto/${id}`
    );
    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          id: data.id,
          enabledSale: data.habilitadoParaVenda === 'S',
          internalName: data.nomeInterno,
          commercialName: data.nomeComercial,
          validity: {
            durationType:
              data.tipoDuracaoVigencia === 'A'
                ? 'years'
                : data.tipoDuracaoVigencia === 'M'
                ? 'months'
                : 'cancelCustomer',
            durationValueMonth:
              data.tipoDuracaoVigencia === 'M'
                ? data.valorDuracaoVigencia
                : null,
            durationValueYear:
              data.tipoDuracaoVigencia === 'A'
                ? data.valorDuracaoVigencia
                : null,
            validityStartType: {
              id: data.tipoInicioVigencia,
            },
          },
          grossMinimumPremiumValue: data.valorPremioBrutoMinimo,
          pricing: {
            type: data.tipoPrecificacao === 'F' ? 'fixed' : 'coverages',
            fixedGrossPremiumValue: data.valorPremioBrutoFixo,
          },
          ageSaleRestriction: {
            min: data.restricaoVendaIdade?.idadeMinima,
            max: data.restricaoVendaIdade?.idadeMaxima,
          },
          saleRestriction: {
            numberOfSales: data.restricaoHistoricoVenda?.numeroVendas,
            salesStatus: {
              id: data.restricaoHistoricoVenda?.statusVenda,
            },
          },
          beneficiaries: {
            min: data.limitesContratacao?.minimoBeneficiarios,
            max: data.limitesContratacao?.maximoBeneficiarios,
          },
          partnerCodes: (data.identificadoresGrupoVariante ?? []).map(
            (partnerCode) => ({
              partnerCoverageId: partnerCode.idParceiroProduto,
              group: {
                name: partnerCode.grupoEmpresarial.nome,
              },
              partner: {
                name: partnerCode.nomeParceiro,
              },
            })
          ),
          taxRates: (data.aliquotasImposto ?? []).map(
            ({
              tipoAliquota,
              tipoImpostoSigla,
              valorAliquota,
              nomeTipoAliquota,
            }) => ({
              typeAliquot: {
                id: `${nomeTipoAliquota} ${valorAliquota}`,
                name: tipoAliquota,
              },
              typeTaxRate: {
                id: tipoImpostoSigla,
                name: tipoImpostoSigla,
              },
              valueAliquot: valorAliquota,
            })
          ),
          paymentPeriodicities: (data.periodicidadesDePagamento ?? []).map(
            (periodicity) => ({
              id: periodicity.id,
              name: periodicity.nome,
            })
          ),
        } as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getVariantsByProduct(variantId) {
    const { data, status } = await Context.Resource.http.get<
      IVarianteSimples[]
    >(
      `bff-simulefacil/v1/variante-produto/produto/${variantId}/listagem-simples`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ id, ...props }) =>
            <IVariantSimple>{
              id,
              enabledSale: props.habilitadoParaVenda === 'S',
              internalName: props.nomeInterno,
              commercialName: props.nomeComercial,
              validityName: props.descricaoTipoDuracaoVigencia,
              productName: props.nomeProduto,
              branchName: props.nomeRamo,
            }
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getPartnerShipTypes() {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/parceria/tipos-parceria`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ idTipoParceria, descricaoTipoParceria }: any) =>
            <IPartnerShipType>{
              id: idTipoParceria,
              description: descricaoTipoParceria,
            }
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getCoins() {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/moeda`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ sigla, descricaoMoeda }: any) =>
            <ICoin>{
              id: sigla,
              name: descricaoMoeda,
            }
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async createGrouper(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/agrupador`,
      [
        {
          nomeInterno: payload.internalName,
          nomeComercial: payload.commercialName,
        },
      ]
    );
    if (status === 201) {
      return {
        status: 'OK',
        payload: {
          id: data[0].id,
          internalName: data[0].nomeInterno,
          commercialName: data[0].nomeComercial,
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getGroupers() {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/agrupador`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ id, nomeInterno, nomeComercial }: any) =>
            <IGrouper>{
              id,
              commercialName: nomeComercial,
              internalName: nomeInterno,
            }
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getProduct(id) {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/produto/${id}`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          id: data.id,
          internalName: data.nomeInterno,
          blockTicket: {
            id: data.idBlocoNumeracaoBilhete,
            name: '###',
          },
          currency: {
            id: data.moeda.sigla,
            name: data.moeda.descricaoMoeda,
          },
          capitalization: {
            id: data.idPlanoSorteioCapitalizacao,
            name: '###',
          },
          grouper: {
            id: data.agrupadorProduto?.id,
            name: data.agrupadorProduto?.nomeInterno,
          },
          enabledSales: data.habilitadoParaVenda === 'S',
          branch: {
            id: data.ramoProduto.idRamo,
            name: data.ramoProduto.descricaoRamo,
          },
          coverage: {
            enabled: true,
            max: data.limiteContratacao?.maximoCoberturas,
            min: data.limiteContratacao?.minimoCoberturas,
          },
          assistance: {
            enabled: true,
            max: data.limiteContratacao?.maximoAssistencias,
            min: data.limiteContratacao?.minimoAssistencias,
          },
          partners: (data.parcerias ?? []).map((parceria: any) => ({
            typePartnerShip: {
              id: parceria.tipoParceria,
              name: parceria.descricaoTipoParceria,
            },
            salePartner: {
              id: parceria.idParceiro,
              name: parceria.nomeParceiro,
            },
          })),
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async createProduct(payload) {
    const { data, status } = await Context.Resource.http.post(
      `bff-simulefacil/v1/produto`,
      [
        {
          nomeInterno: payload.internalName,
          idAgrupador: payload.grouper?.id ?? null,
          siglaMoeda: payload.currency?.id ?? null,
          idRamoProduto: payload.branch?.id ?? null,
          habilitadoParaVenda: payload.enabledSales ? 'S' : 'N',
          idBlocoNumeracaoBilhete: payload.blockTicket.id,
          idPlanoSorteioCapitalizacao: payload.capitalization.id,
          limiteContratacao: {
            minimoCoberturas: payload.coverage?.min ?? null,
            maximoCoberturas: payload.coverage?.max ?? null,
            minimoAssistencias: payload.assistance?.min ?? null,
            maximoAssistencias: payload.assistance?.max ?? null,
          },
          parcerias: (payload.partners ?? []).map((partner) => ({
            idParceiro: partner.salePartner.id,
            tipoParceria: partner.typePartnerShip.id,
          })),
        },
      ]
    );
    if (status === 201) {
      const [productData] = data;
      return {
        status: 'OK',
        payload: {
          id: productData.id,
        },
      } as any;
    }
    return {
      status: 'FAIL',
      payload,
      message: data?.detail ?? 'Erro ao salvar o produto',
    };
  },
  async updateProduct(id, payload) {
    const { data, status } = await Context.Resource.http.put(
      `bff-simulefacil/v1/produto/${id}`,
      {
        id,
        nomeInterno: payload.internalName,
        idAgrupador: payload.grouper?.id ?? null,
        siglaMoeda: payload.currency?.id ?? null,
        idRamoProduto: payload.branch?.id ?? null,
        habilitadoParaVenda: payload.enabledSales ? 'S' : 'N',
        idBlocoNumeracaoBilhete: payload.blockTicket.id,
        idPlanoSorteioCapitalizacao: payload.capitalization.id,
        limiteContratacao: {
          minimoCoberturas: payload.coverage?.min ?? null,
          maximoCoberturas: payload.coverage?.max ?? null,
          minimoAssistencias: payload.assistance?.min ?? null,
          maximoAssistencias: payload.assistance?.max ?? null,
        },
        parcerias: (payload.partners ?? []).map((partner) => ({
          idParceiro: partner.salePartner.id,
          tipoParceria: partner.typePartnerShip.id,
        })),
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: { id: data.id } as any,
      } as any;
    }
    return {
      status: 'FAIL',
      payload,
      message: data?.detail ?? 'Erro ao salvar o produto',
    };
  },
  async updateVariant(id, payload) {
    const { data, status } = await Context.Resource.http.put(
      `bff-simulefacil/v1/variante-produto/${id}`,
      {
        id,
        idProduto: payload.productId,
        nomeInterno: payload.internalName,
        nomeComercial: payload.commercialName,
        habilitadoParaVenda: payload.enabledSale ? 'S' : 'N',
        tipoDuracaoVigencia:
          payload.validity.durationType === 'months'
            ? 'M'
            : payload.validity.durationType === 'years'
            ? 'A'
            : 'R',
        valorDuracaoVigencia:
          payload.validity.durationType === 'months'
            ? payload.validity.durationValueMonth
            : payload.validity.durationType === 'years'
            ? payload.validity.durationValueYear
            : null,
        tipoInicioVigencia: payload.validity.validityStartType.id,
        valorPremioBrutoMinimo: payload.grossMinimumPremiumValue,
        tipoPrecificacao: payload.pricing.type === 'fixed' ? 'F' : 'C',
        valorPremioBrutoFixo: payload.pricing.fixedGrossPremiumValue,
        restricaoVendaIdade: {
          idVariante: null,
          idadeMinima: payload.ageSaleRestriction.min,
          idadeMaxima: payload.ageSaleRestriction.max,
        },
        restricaoHistoricoVenda: {
          idVariante: null,
          statusVenda: payload.saleRestriction.salesStatus.id,
          numeroVendas: payload.saleRestriction.numberOfSales,
        },
        limitesContratacao: {
          idVariante: null,
          minimoBeneficiarios: payload.beneficiaries.min,
          maximoBeneficiarios: payload.beneficiaries.max,
        },
        gruposDeAcessoVariante: [
          {
            idVarianteAcesso: null,
            idGrupoEmpresarial: '6a2e50d7-e692-4958-9d72-3f7f5c7c035c',
            flagConsiderarDescendentes: 'S',
          },
        ],
        identificadoresGrupoVariante: (payload.partnerCodes ?? []).map(
          (partnerCode) => ({
            idVariacaoProduto: null,
            idGrupoEmpresarial: partnerCode.group.id,
            idParceiroProduto: partnerCode.partnerCoverageId,
          })
        ),
        aliquotasImposto: (payload.taxRates ?? []).map((taxRate) => ({
          idVarianteProduto: null,
          tipoImpostoSigla: taxRate?.typeTaxRate?.id,
          tipoAliquota: taxRate?.typeAliquot?.id,
          valorAliquota: taxRate?.valueAliquot,
          siglaValorAliquota: 'BRL',
        })),
        periodicidadePagamento: {
          idProdutoVariacao: null,
          tipoPeriodicidadePagamentos: (payload.paymentPeriodicities ?? []).map(
            (paymentPeriodicity) => ({
              id: paymentPeriodicity?.id,
              nome: paymentPeriodicity?.name,
            })
          ),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: { id: data.id } as any,
      } as any;
    }
    return {
      status: 'FAIL',
      payload,
      message: data?.detail ?? 'Erro ao salvar o variente',
    };
  },
  async deleteVariant(id) {
    const { data, status } = await Context.Resource.http.delete(
      `bff-simulefacil/v1/variante-produto/${id}`
    );

    if (status === 204) {
      return {
        status: 'OK',
        payload: { id: data.id } as any,
      } as any;
    }
    return {
      status: 'FAIL',
      message: data?.mensagem,
    };
  },
  async getAliquotTypes() {
    const { data, status } = await Context.Resource.http.get<ITipoAliquota[]>(
      `bff-simulefacil/v1/aliquotaimposto/tipos-aliquota`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ idTipoAliquota, descricaoTipoAliquota }) =>
            <IAliquotTypes>{
              acronym: idTipoAliquota,
              name: `${descricaoTipoAliquota}`,
            }
        ) as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getBusinessGroupsByPartner(partnerId) {
    const { data, status } = await Context.Resource.http.get<
      IGrupoEmpresarial[]
    >(`bff-simulefacil/v1/grupo-empresarial/parceiro/${partnerId}`);

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ id, nome }) =>
            <IBusinessGroup>{
              id,
              name: nome,
            }
        ) as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getTaxTypes() {
    const { data, status } = await Context.Resource.http.get<ITipoImposto[]>(
      `bff-simulefacil/v1/tipo-imposto`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ nome, sigla }) =>
            <ITaxTypes>{
              acronym: sigla,
              name: nome,
            }
        ) as any,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getProducts(query) {
    const { data, status } =
      await Context.Resource.http.get<IProdutosPaginados>(
        `bff-simulefacil/v1/produto/consulta-paginada/elementos-pagina/10/pagina/${
          query?.page ?? 1
        }`,
        {
          params: {
            ...(query?.product ? { nomeProduto: query?.product } : {}),
            ...(query?.branch ? { idRamo: query?.branch } : {}),
          },
        }
      );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          items: (data.produtos ?? []).map(
            (p: any) =>
              <IProduct>{
                blockTicket: {
                  id: '123',
                  name: '123',
                },

                id: p.id,
                enabledSales: p.habilitadoParaVenda === 'S',
                assistance: {
                  enabled: true,
                  min: p.limiteContratacao?.minimoAssistencias ?? null,
                  max: p.limiteContratacao?.maximoAssistencias ?? null,
                },
                capitalization: {
                  id: '123',
                  name: '123',
                },
                coverage: {
                  enabled: true,
                  min: p.limiteContratacao?.minimoCoberturas ?? null,
                  max: p.limiteContratacao?.maximoCoberturas ?? null,
                },
                currency: {
                  id: p.siglaMoeda,
                  name: p.siglaMoeda,
                },
                grouper: {
                  id: p.agrupadorProduto,
                  name: p.nomeComercial,
                },
                partners: [],
                internalName: p.nomeInterno,
                active: p.ativo,
                group: p.grupo,
                branch: {
                  id: p.ramoProduto?.idRamo,
                  name: p.ramoProduto?.descricaoRamo,
                },
              }
          ),
          meta: {
            page: data.metadata.pagina,
            pages: data.metadata.totalPaginas,
            limit: data.metadata.limitePorPagina,
            totalItems: data.metadata.totalPaginas,
          },
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getBranchs() {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/ramo-produto`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ idRamo, descricaoRamo }: any) =>
            <IBranch>{
              id: idRamo,
              description: descricaoRamo,
            }
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getInsurancePartners() {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/parceiro-seguradora`
    );

    if (status === 200) {
      return data.map((insurancePartner: any) => ({
        title: insurancePartner.descricaoParceiroSeguradora,
        value: {
          id: insurancePartner.idParceiroSeguradora,
          description: insurancePartner.descricaoParceiroSeguradora,
        },
        keywords: [insurancePartner.descricaoParceiroSeguradora],
        data: insurancePartner,
      }));
    }
    return [];
  },
  async getSalesPartners() {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/parceiro`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ id, descricaoParceiro }: any) =>
            <ISalePartner>{
              id,
              description: descricaoParceiro,
            }
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getSaleLimits() {
    const { data, status } = await Context.Resource.http.get<
      ITipoStatusVenda[]
    >(
      `bff-simulefacil/v1/restricao-venda-historico/tipos-status-historico-venda`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ idTipoStatusVenda, descricaoTipoStatusVenda }) =>
            <ISaleLimit>{
              id: idTipoStatusVenda,
              description: descricaoTipoStatusVenda,
            }
        ),
      } as any;
    }
    return [];
  },
  async getValidities() {
    const { data, status } = await Context.Resource.http.get<
      IInicioVigenciaProduto[]
    >(`bff-simulefacil/v1/variante-produto/tipos-inicio-vigencia`);

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ idInicioVigencia, descricaoInicioVigencia }) =>
            <IValidity>{
              id: idInicioVigencia,
              description: descricaoInicioVigencia,
            }
        ),
      } as any;
    }
    return [];
  },
  async getPaymentPeriodicities() {
    const { data, status } = await Context.Resource.http.get<
      IPeriodicidadePagamento[]
    >(
      `bff-simulefacil/v1/periodicidade-pagamento/tipo-periodicidade-pagamento`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({ id, nome }) =>
            <IPaymentPeriodicity>{
              id,
              name: nome,
            }
        ),
      } as any;
    }
    return [];
  },

  async getRiskTypeCoverages() {
    const { data, status } = await Context.Resource.http.get<ITipoRisco[]>(
      `bff-simulefacil/v1/tipo-risco`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(({ idTipoRisco, descricaoTipoRisco }) => ({
          id: idTipoRisco,
          description: descricaoTipoRisco,
        })),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getNotificationCategories() {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/categoria-notificacoes`
    );

    if (status === 200) {
      return data.map((category: any) => ({
        id: category.id,
        description: category.descricao,
      }));
    }
    return [];
  },
  async getNotificationTypes() {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/tipo-notificacoes`
    );

    if (status === 200) {
      return data.map((type: any) => ({
        id: type.id,
        description: type.tipo,
      }));
    }
    return [];
  },
  async uploadProposalDocument(file: File) {
    const formData = new FormData();
    formData.append('', file);

    const { data, status } = await Context.Resource.http.post(
      'bff-simulefacil/v1/comum-documentos/upload-proposta',
      formData
    );

    if (status === 200)
      return {
        status: 'OK',
        payload: data?.uri ?? '',
      };
    return {
      status: 'FAIL',
      message: 'Ocorreu um erro ao tentar realizar o upload',
      payload: '',
    };
  },
  async uploadProductCoverageRatesWorksheet(
    variantId: string,
    { file, prizeTable }
  ) {
    const formData = new FormData();
    formData.append('excel', file);

    const { status, data } = await Context.Resource.http.post(
      `bff-simulefacil/v1/importar-exportar/excel/importar/tabela-premio/${prizeTable.id}`,
      formData
    );

    if (status === 200)
      return {
        status: 'OK',
        payload: '',
      };
    return {
      status: 'FAIL',
      message: data.erro ?? 'Ocorreu um erro ao tentar realizar o upload',
      payload: '',
    };
  },
  async downloadProductCoverageRatesWorksheetModel(variantId) {
    const { data, status } = await Context.Resource.http.get(
      `bff-simulefacil/v1/importar-exportar/excel/taxas/cobertura/variante-produto/${variantId}`,
      {
        responseType: 'blob',
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          file: data,
          filename: 'modelo.xlsx',
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getPrizeTablesByVariant(variantId, queryParams) {
    const { data, status } = await Context.Resource.http.get<TabelaPremio[]>(
      `bff-simulefacil/v1/tabela-premio/variante-produto/${variantId}`,
      {
        params: {
          ...(queryParams.query ? { nomeCobertura: queryParams.query } : {}),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(
          ({
            nome,
            tipoVariacao,
            id,
            dataFimValidade,
            dataInicioValidade,
            idVariante,
          }) =>
            ({
              name: nome,
              variation: tipoVariacao,
              variantId: idVariante,
              startDate: dataInicioValidade,
              endDate: dataFimValidade,
              id,
            } as unknown as IPrizeTable)
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getCapitalizations() {
    const { data, status } =
      await Context.Resource.http.get<ICapitalizacaoLista>(
        `bff-simulefacil/v1/capitalizacao`
      );

    if (status === 200) {
      return {
        status: 'OK',
        payload: (data.capitalizacoes ?? []).map(
          ({
            id,
            nomeComercial,
            textoExibicaoPrincipal,
            textoExibicaoResumo,
            textoImpressaoProposta,
            valorPremio,
            controleNumeracao,
            descricaoControleNumeracao,
            descricaoPeriodicidadeSorteio,
            periodicidadeSorteio,
          }) => ({
            id,
            productId: 'null',
            name: nomeComercial,
            raffleFrequency: {
              id: periodicidadeSorteio,
              description: descricaoPeriodicidadeSorteio,
            },
            drawModality: {
              id: controleNumeracao,
              description: descricaoControleNumeracao,
            },
            exhibitionTexts: {
              main: textoExibicaoPrincipal,
              proposedPrint: textoImpressaoProposta,
              saleSummary: textoExibicaoResumo,
            },
            premiumCapitalValue: valorPremio,
          })
        ),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getTicketBlocks() {
    const { data, status } = await Context.Resource.http.get<{
      blocoNumeracaoBilhete: IBlocoNumeracaoBilhete[];
    }>(`bff-simulefacil/v1/bloco-numeracao-bilhete`);

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.blocoNumeracaoBilhete.map(
          ({ idBlocoNumeracaoBilhete, nomeBlocoNumeracaoBilhete }: any) => ({
            id: idBlocoNumeracaoBilhete,
            blockName: nomeBlocoNumeracaoBilhete,
          })
        ) as IBlockTicket[],
      };
    }

    return {
      status: 'FAIL',
    };
  },
});
